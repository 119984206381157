
export default function (context) {
    const utmParams = Object.keys(context.query)
      .filter(key => key.startsWith('utm_'))
      .reduce((obj, key) => {
        obj[key] = context.query[key];
        return obj;
      }, {});
  
    context.store.commit('setUtmParams', utmParams);
  }